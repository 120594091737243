<template>
  <div id="wxAuth">
    <div class="icon">
      <van-icon name="wechat" />
      <p>微信绑定</p>
    </div>
    <div class="btn">
      <van-button type="primary" block @click="onWechat">确认</van-button>
      <van-button type="default" block @click="$router.go(-1)">返回</van-button>
    </div>
  </div>
</template>

<script>
import { wechat } from "@/api/myself/myself";
import { Toast } from "vant";
export default {
  data() {
    return {
      code: this.getUrlParams().code,
    };
  },
  methods: {
    // 【转换】获取路径"?"后面的参数
    getUrlParams() {
      let url = location.search;
      let params = new Object();
      if (url.indexOf("?") != -1) {
        url = url.slice(1).split("&");
        for (let i of url) {
          params[i.split("=")[0]] = decodeURI(i.split("=")[1]);
        }
      }
      return params;
    },

    // 【监听】微信绑定
    onWechat() {
      let data = {
        code: this.code,
      };
      wechat(data).then((res) => {
        if (res.success) {
          Toast("微信绑定成功");
          this.$router.push("/myself/myselfInfo");
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#wxAuth {
  height: 100%;
  background: $bg-color;
  .icon {
    height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #5ac86e;
    color: #fff;
    .van-icon {
      font-size: 0.8rem;
    }
  }
  .btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.15rem;
    > .van-button {
      margin: 0.1rem 0;
      font-size: 0.18rem;
    }
  }
}
</style>